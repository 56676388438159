<template>
  <div class="home-container">

    <van-tabs v-model="active" color="#a082f0" line-height="4" sticky size="16">
      <van-tab :title="$t('md502.home')">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </van-swipe-item>
        </van-swipe>
        <div class="hot-game">
          <div class="hot-title-div">
            <div>
              <i class="service van-icon van-icon-hot-o"></i>
              <span style="margin-left: 6px;">{{ $t('index.task') }}</span>
            </div>

            <div @click="gotoMenu('/Game')">
              <span>{{ $t('index.more') }}</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div>
          <div class="hot-items-div">
            <van-grid :border=false :column-num="4" :icon-size="20">
              <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
                <van-image class="game_item_img" :src="v.ico">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <span>{{ v.name }}</span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>

        <div class="hot-title-div">
          <div>
            <i class="service van-icon van-icon-hot-o"></i>
            <span style="margin-left: 6px;">{{ $t('md502.home_txt1') }}</span>
          </div>
        </div>
        <div class="body-box">

          <div class="box1">

            <vue-seamless-scroll :data="listData" :class-option="classOption" class="chatList">
              <div class="item" v-for="(item, index) in listData" :key="index">
                <span class="title" v-text="item"></span>
              </div>
            </vue-seamless-scroll>
          </div>
          <div style="color: red; font-weight: bolder; padding: 5px; margin-top: 20px; text-align: center;">
            {{ $t('md502.home_txt2') }}
            
          </div>
          <div style="font-weight: bolder; padding: 5px; margin-top: 20px; text-align: center;">
            {{ $t('md502.home_txt3') }}
            
          </div>
        </div>
      </van-tab>
      <van-tab :title="$t('md502.feedback')">
        <div class="hot-game">
          <div class="hot-title-div">
            <div>
              <i class="service van-icon van-icon-hot-o"></i>
              <span style="margin-left: 6px;">{{ $t('index.task') }}</span>
            </div>
            <div @click="gotoMenu('/Game')">
              <span>{{ $t('index.more') }}</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div>
          <div class="hot-items-div">
            <van-grid :border=false :column-num="4" :icon-size="20">
              <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
                <van-image class="game_item_img" :src="v.ico">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <span>{{ v.name }}</span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>

        <div class="hot-title-div">
          <div>
            <i class="service van-icon van-icon-hot-o"></i>
            <span style="margin-left: 6px;">{{ $t('md502.home_txt1') }}</span>
          </div>
        </div>
        <div class="body-box">
          <div style="height: 600px;">

            <vue-seamless-scroll :data="feedback" :class-option="classOption" class="chatList">
              <!-- <div class="item" v-for="(item, index) in feedback" :key="index">
                <span class="title" v-text="item.title"></span><span class="date" v-text="item.date"></span>
              </div> -->
              <div class="item2" v-for="(item, index) in feedback" :key="index">
                <div class="img"><img height="80px" :src="item.head_img" width="80px" alt=""
                    style="border-radius: 100px;"></div>
                <div class="box2">
                  <div class="content">{{ item.content }}</div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
         
        </div>
      </van-tab>
      <van-tab :title="$t('md502.hot_videos')">

        <div class="hot-game">
          <div class="hot-title-div">
            <div>
              <i class="service van-icon van-icon-hot-o"></i>
              <span style="margin-left: 6px;">{{ $t('index.task') }}</span>
            </div>
            <div @click="gotoMenu('/Game')">
              <span>{{ $t('index.more') }}</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div>
          <div class="hot-items-div">
            <van-grid :border=false :column-num="4" :icon-size="20">
              <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
                <van-image class="game_item_img" :src="v.ico">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <span>{{ v.name }}</span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
          :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
          <div class="hot-recommend">
            <div class="hot-title-div">
              <div>
                <i class="service van-icon van-icon-hot-o"></i>
                <span style="margin-left: 6px;">{{ $t('index.hot') }}</span>
              </div>
              <div>
                <span @click="gotoMenu('/Video')">{{ $t('index.more') }}</span>
                <van-icon name="arrow" color="#979799" />
              </div>
            </div>
            <div class="movie_list_0">
              <swiper class="movie_swiper" :options="movielistSwiperOption">
                <swiper-slide v-for="(v, key) in movielist_0" :key="key">
                  <van-image class="movie_cover" @click="toPlayVideo(v.id)" round :src="v.cover">
                    <template v-slot:loading>
                      <van-loading type="circular" />
                    </template>
                  </van-image>
                  <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                  <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                  <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                  <div class="movie-list-item-bottom">
                    <div class="movie-time-div">
                      <span>{{ v.title }}</span>
                      <div class="van-count-down">{{ v.time }}</div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

          <div class="hot-title-div">
            <div>
              <i class="service van-icon van-icon-hot-o"></i>
              <span style="margin-left: 6px;">{{ $t('index.more') }}</span>
            </div>
            <div @click="gotoMenu('/Video')">
              <span>{{ $t('index.recmonmand') }}</span>
                <van-icon name="arrow" color="#979799" />
            </div>
          </div>
            <div class="movie_list_1">
              <div class="movie-list-item" v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
                <!-- <img class="cover_img" :data-src="v.cover" alt="" v-lazy="v.cover"> -->
                <!-- cover -->
                <van-image class="cover_img"  fit="cover" round :src="v.cover">
                    <template v-slot:loading>
                      <van-loading type="circular" />
                    </template>
                  </van-image>
                <div class="movie-list-item-bottom">
                  <!-- <div class="movie-time-div">
                  </div> -->
                    <span>{{ v.title }}</span>
                    <!-- <span>{{ $t('video.play') }}:{{ v.count }}</span> -->
                </div>
              </div>
              <div class="hot-recommend-more" @click="gotoMenu('/Video')">{{ $t('index.more') }}</div>
            </div>
          </div>
        </van-pull-refresh>

      </van-tab>
      <!-- <van-tab :title="$t('md502.about')">
        <div class="hot-game">
          <div class="hot-title-div">
            <div>
              <i class="service van-icon van-icon-hot-o"></i>
              <span style="margin-left: 6px;">{{ $t('index.task') }}</span>
            </div>
            <div @click="gotoMenu('/Game')">
              <span>{{ $t('index.more') }}</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div>
          <div class="hot-items-div">
            <van-grid :border=false :column-num="4" :icon-size="20">
              <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
                <van-image class="game_item_img" :src="v.ico">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <span>{{ v.name }}</span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
        <div class="home">
          <h3>{{ $t('md502.home_txt4') }} &amp; {{ $t('md502.home_txt5') }}
          </h3>
          
         <div v-html="about"></div>
          <div style="height: 100px;"></div>
        </div>

      </van-tab> -->
    </van-tabs>
    <div class="mask" v-if="isLoading && video_login == 1 && !token">
      <van-loading type="spinner" size="80px" />
    </div>
    <div class="home-scroll">

    </div>
    <!-- <div class="notice-bar">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#a082f0"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div> -->
    <img class="kefu" v-if="iskefu == 1" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        
      }
    }
  },
  data() {
    return {
      iskefu:0,
      kefu:"",
      listData: [],
      feedback: [],
      active: 0,
      about:"",
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [],
      isLoading: true,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      video_login: this.$store.getters.getBaseInfo.video_login || 1,
      token: localStorage.getItem('token')
    };
  },
  methods: {
    toKefu() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
                window.location.href = this.kefu
            }else{
                window.open(this.kefu)
            }
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
        }
      }

    },
    toPlayVideo(id) {

      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/PlayVideo?id=' + id })
        }
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1

        this.$nextTick(() => {
          this.lazyLoadImg()
        })
      })

    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      this.banners = data.banners;
    },
    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {
        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          return this.$router.push({ path: '/Login' })
        }
        this.listData = res.data.winners
        this.feedback = res.data.feedbacklist
        this.about = res.data.about
        this.kefu = res.data.kefu
        this.iskefu = res.data.iskefu
        this.isLoading = false
        this.$store.commit('setBaseInfoValue', res.data);
        this.getBasicConfig();
      }
    })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 200px;
  background: linear-gradient(270deg, #ea92cf, #a082f0);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  height: 400px;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  // width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}

// .hot-title-div>div:first-child span:before {
//   content: "";
//   display: block;
//   width: 5px;
//   height: 30px;
//   background-color: #a082f0;
//   border-radius: 1px;
//   margin-right: 5px;
// }

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: 10px;
  width: 100%;
  height: 290px;
  position: relative;
}

.movie_list_1 .movie-list-item-bottom {
  
  display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  width: 100%;
    color: #fff;
    padding: 15px;
    font-size: 16px;
    background: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,0)),color-stop(rgba(0,0,0,.3)),to(#000));
    background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(0,0,0,.3),#000);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 10px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  // border-radius: 20px;
  border-radius: 50%;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

::v-deep .van-tab .van-tab__text--ellipsis{
	font-size: 26px;
  line-height: 50px;
  height:50px;
}
.box1 {
  height: 400px;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(60deg, #765e6e, #c1e2f1);
  overflow: hidden;
}
.box2 {
  flex: 1;
  flex-direction: column;
  row-gap: 10px;
  display: flex;
}
.box2 .content{
  flex: 1;
  flex-direction: column;
  row-gap: 20px;
  color: #333;
  padding: 20px;
  font-size: 22px;
  border-radius: 20px;
  background: linear-gradient(60deg,#f7f3f5,#f2f4f5);
}
.item2{
  display: flex;
  margin-bottom: 40px;
  column-gap: 20px;
}

.chatList {
  margin-top: 10px;
  height: calc(100% - 35px);
  overflow-y: hidden;
}

.chatList .item {
  margin-bottom: 10px;
  font-size: 12px;
  color: #fff;
  line-height:36px;
  padding: 30px;
  border-top: 1px solid #fff;
  text-align: left;
}
.chatList .img{
  display: flex;
  height: max-content;
  border-radius: 100%;
  border: 1px solid #e2e2e2;
}

.home,
.body-box {
  margin: 20px;
}
.home div{
  line-height: 50px;

}

.service {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  // background: -webkit-gradient(linear, left top, right top, from(rgba(25, 219, 250, .47)), to(#ea92cf));
  background: linear-gradient(90deg, #a082f0, #ea92cf);
  color: #fff;
}

.kefu {
  position: fixed;
  bottom: 300px;
  right: 60px;
  width: 80px;
  height: 80px;
  z-index: 999;
    background: #fff;
    border-radius: 50%;
  // background: #fff;border-radius: 50px;
}
</style>
