import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
Vue.use(VueI18n);



const i18n = new VueI18n({
    globalInjection: true,
    locale: localStorage.getItem("lang") || store.state.langDefault, // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
    messages: {
      'zh_cn': require('./zh_cn.json'),
      'en_us': require('./en_us.json'),
      'es_spa': require('./es_spa.json'),
      'ms_my': require('./ms_my.json'),
      'vn_vn': require('./vn_vn.json'),
    }
  });

export default i18n;