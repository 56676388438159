<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.Moneylog')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">

        <!-- <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    :finished-text="$t('video.no_more')"
                    @load="onLoad"
                > -->
        <div class="listItem" v-for="(v,key) in moneyList" :key="key">
            <div>
                <div class="listTitle">{{ v.type_txt }}</div>
              <div class="listTime"><div class="listTimeText">{{ v.createtime }}</div></div>
            </div>
            <div style="flex:1"></div>
            <div class="listMoney" :style="v.money<0?'color: rgb(7, 193, 96)':''">{{ v.money }}</div>
          </div>

        <!-- </van-list> -->
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      moneyList: [
      ],
      loading: false,
      finished: false,
      page:1,
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getList(){
      this.$http({
        method: 'get',
        url: 'getMoneylog',
        data: {
          page:this.page,
          limit:10
        }
      }).then(res => {
        this.moneyList = res.data.data
        // this.page++;
      })
    },
    onLoad() {
        // this.getList();
      // let timer = setTimeout(() => {
      //   if (this.refreshing) {
      //     this.moneyList = [];
      //     this.refreshing = false;
      //   }
      //   this.loading = false;
      //   if (this.moneyList.length === this.count) {
      //     this.finished = true;
      //   }
      //   this.finished && clearTimeout(timer);//清除计时器
      // }, 500);
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
        this.getList();
      }, 500);
    },
  },
  created() {
    this.getList();
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .content {
  height: calc(100% - 20px);
  overflow: auto;
}
.container .content .listItem{
  margin-bottom: 20px;
  padding: 20px ;
  position: relative;
  color: #000;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .content .listItem .listTitle{
  font-size: 38px;
  margin-bottom: 20px;
}
.container .content .listItem .listContent{
  border-bottom: 2px solid #f2f2f5;
  padding: 5px 0;
  font-size: 25px;
}
.container .content .listItem .listTime{
  // height: 70px;
}
.container .content .listItem .listMoney{

  color: #dc2037;
}
.container .content .listItem .listTime .listTimeText {
  color: #656566;
  font-size: 30px;
}
</style>
